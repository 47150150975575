import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import SEO from '../components/seo';
import Hero from '../components/hero';
import Tour from '../components/tour';
import HomeSelector from '../components/home-selector';
export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: {
        eq: "The Refuge (Bushfire protection)/Refuge 2 (2 Segment Version)/Renders (External)/R_2_Ext_0002.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Emergency Solutions" mdxType="SEO" />
    <Hero heroImage={props.data.heroImage} styles={{
      backgroundPositionX: '35%'
    }} mdxType="Hero">
  <h1 className="text-3xl">Emergency Solutions</h1>
  <p className="text-xl">Safer, faster, self-sufficient relief.</p>
    </Hero>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/modular-homes/refuge-standard"
      }}>{`Refuge`}</a>{` models have been designed in response to the crisis of individuals being left homeless in natural disasters across Australia. The construction and materials are rated for `}<strong parentName="p">{`one hour of fire exposure`}</strong>{`, providing secure access to essential amenities when they are needed most, including a factory-built kitchen, bathroom, laundry, and toilet, all pre-wired and pre-plumbed. These builds can be assembled in less than `}<strong parentName="p">{`24 hours`}</strong>{` on a prepared site, at a scale to suit the individual circumstances of those affected—from single-room structures to entire family homes.`}</p>
    {
      /* TODO: gallery */
    }
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5632c3d759afac13764aa0a675394e91/b89a9/13_S_F_External_0000.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAc7GESsj/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxEg/9oACAEBAAEFAgralcO8f//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAATAf/aAAgBAgEBPwGyuv/EABsQAAIBBQAAAAAAAAAAAAAAAAABMQIRICFB/9oACAEBAAY/AruueC2Rh//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExYUH/2gAIAQEAAT8hAiPWeeQgvYS3ya4K+bCMAn//2gAMAwEAAgADAAAAEE8v/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIVH/2gAIAQMBAT8QrCEf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBR/9oACAECAQE/EBZN/wD/xAAbEAADAQEAAwAAAAAAAAAAAAABESEAMVGRof/aAAgBAQABPxAa/CjArB74GojBymRq+n1iA7tiZrF0NZbg7v/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "13 S F External 0000",
            "title": "13 S F External 0000",
            "src": "/static/5632c3d759afac13764aa0a675394e91/b89a9/13_S_F_External_0000.jpg",
            "srcSet": ["/static/5632c3d759afac13764aa0a675394e91/c635c/13_S_F_External_0000.jpg 500w", "/static/5632c3d759afac13764aa0a675394e91/7e3f6/13_S_F_External_0000.jpg 1000w", "/static/5632c3d759afac13764aa0a675394e91/b89a9/13_S_F_External_0000.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3188503166d4877f34edf287e732e70a/b89a9/EX_8_Ext_0004.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFd5mkeCD//xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAyIxQf/aAAgBAQABBQJndpsYc1G56e//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEgH/2gAIAQIBAT8BpWv/xAAaEAACAgMAAAAAAAAAAAAAAAABEQAhAiAx/9oACAEBAAY/AiEFKpTmOn//xAAbEAACAgMBAAAAAAAAAAAAAAABIQAREDFBof/aAAgBAQABPyFIRJbljIKxju4s9jLDuV//2gAMAwEAAgADAAAAEOM//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8Qh//EABYRAQEBAAAAAAAAAAAAAAAAAAEAUf/aAAgBAgEBPxAaTpf/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFhUf/aAAgBAQABPxA9KaFqDuCs8xPTW3k6r2Ihx503xIuoVe7EHUxQ+E//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "EX 8 Ext 0004",
            "title": "EX 8 Ext 0004",
            "src": "/static/3188503166d4877f34edf287e732e70a/b89a9/EX_8_Ext_0004.jpg",
            "srcSet": ["/static/3188503166d4877f34edf287e732e70a/c635c/EX_8_Ext_0004.jpg 500w", "/static/3188503166d4877f34edf287e732e70a/7e3f6/EX_8_Ext_0004.jpg 1000w", "/static/3188503166d4877f34edf287e732e70a/b89a9/EX_8_Ext_0004.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bceea2ff0677722575d0e6adda5d6bfb/b89a9/External_0100.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABpn2bMIMGf//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMREiL/2gAIAQEAAQUCXoryUOrDEmJI/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAABMB/9oACAECAQE/Aaqa/8QAHBAAAgEFAQAAAAAAAAAAAAAAAQIAESAiMmGR/9oACAEBAAY/AqMI+Kg8mq+2f//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExEFGh/9oACAEBAAE/IbqtT0yxBSU4e8E1BU1BGCf/2gAMAwEAAgADAAAAEDAf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EDCwv//EABgRAAIDAAAAAAAAAAAAAAAAAAAhATFh/9oACAECAQE/EIR2bn//xAAeEAEBAAEDBQAAAAAAAAAAAAABEQAhMVFBYYHR4f/aAAgBAQABPxDQZBpH7ggVSXvyvGd48vWDTY7t3xUEUp0cHkB1g5//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "External 0100",
            "title": "External 0100",
            "src": "/static/bceea2ff0677722575d0e6adda5d6bfb/b89a9/External_0100.jpg",
            "srcSet": ["/static/bceea2ff0677722575d0e6adda5d6bfb/c635c/External_0100.jpg 500w", "/static/bceea2ff0677722575d0e6adda5d6bfb/7e3f6/External_0100.jpg 1000w", "/static/bceea2ff0677722575d0e6adda5d6bfb/b89a9/External_0100.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3d67d77e89c39f7f116dedc34fd90ad/b89a9/R_2_internal_2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG6aUsiaA//xAAZEAADAAMAAAAAAAAAAAAAAAAAAQISE0H/2gAIAQEAAQUC1Juomm5xGdP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEQESIEFR/9oACAEBAAY/AmivCsaw/8QAGBABAQEBAQAAAAAAAAAAAAAAAQAhEUH/2gAIAQEAAT8hMkIf1q2B+Exd6OyCuX//2gAMAwEAAgADAAAAENcv/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QCf/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCMf//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQWFxwf/aAAgBAQABPxBzpl10esWioI1GZDAgBW8l+4lUYznBKVY7cQkPHXgz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "R 2 internal 2",
            "title": "R 2 internal 2",
            "src": "/static/e3d67d77e89c39f7f116dedc34fd90ad/b89a9/R_2_internal_2.jpg",
            "srcSet": ["/static/e3d67d77e89c39f7f116dedc34fd90ad/c635c/R_2_internal_2.jpg 500w", "/static/e3d67d77e89c39f7f116dedc34fd90ad/7e3f6/R_2_internal_2.jpg 1000w", "/static/e3d67d77e89c39f7f116dedc34fd90ad/b89a9/R_2_internal_2.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5233c2c9a5c436b1c9b9efebbab34548/b89a9/SX_18_Ext_0004.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABa6HIN5CZf//EABgQAAMBAQAAAAAAAAAAAAAAAAECAxEg/9oACAEBAAEFAmozDCZNbOP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQADAAAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8Btls//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMhEgYf/aAAgBAQAGPwJ1QpOWHwqtP//EABoQAAIDAQEAAAAAAAAAAAAAAAERABAhQaH/2gAIAQEAAT8hFgQcgVTcTY2h6V2v/9oADAMBAAIAAwAAABBc/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPxCH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxAj/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8QAYoCKpfWWCMK46rViCjm2ry+vlRChhtGIFZP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "SX 18 Ext 0004",
            "title": "SX 18 Ext 0004",
            "src": "/static/5233c2c9a5c436b1c9b9efebbab34548/b89a9/SX_18_Ext_0004.jpg",
            "srcSet": ["/static/5233c2c9a5c436b1c9b9efebbab34548/c635c/SX_18_Ext_0004.jpg 500w", "/static/5233c2c9a5c436b1c9b9efebbab34548/7e3f6/SX_18_Ext_0004.jpg 1000w", "/static/5233c2c9a5c436b1c9b9efebbab34548/b89a9/SX_18_Ext_0004.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0a66d7a11f97968c8cefe1a71cc56f1a/b89a9/SX_18_Int_0001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB6UbMCGcb/8QAGhABAAIDAQAAAAAAAAAAAAAAAQACESEiMv/aAAgBAQABBQK1tj1klfSZjp//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEABj8CSt//xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhYRAx/9oACAEBAAE/IURJmxLS3ppxq9IsJH//2gAMAwEAAgADAAAAEG/P/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8QiP/EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBwVH//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhUTFBgXH/2gAIAQEAAT8QFYO46bxivsHLkHLcNCJeDnUKa3yEwEPZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "SX 18 Int 0001",
            "title": "SX 18 Int 0001",
            "src": "/static/0a66d7a11f97968c8cefe1a71cc56f1a/b89a9/SX_18_Int_0001.jpg",
            "srcSet": ["/static/0a66d7a11f97968c8cefe1a71cc56f1a/c635c/SX_18_Int_0001.jpg 500w", "/static/0a66d7a11f97968c8cefe1a71cc56f1a/7e3f6/SX_18_Int_0001.jpg 1000w", "/static/0a66d7a11f97968c8cefe1a71cc56f1a/b89a9/SX_18_Int_0001.jpg 1920w"],
            "sizes": "(max-width: 1920px) 100vw, 1920px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The affordability of factory-fabricated construction means that The Refuge can provide quickly-available and high-quality accommodation and amenities  where housing is lost due to natural disasters. The modules are the right dimensions and weight to fit on a trailer behind a 4WD, and can be delivered by a tractor fitted with forklift tynes to almost anywhere.`}</p>
    <p>{`A fully self-contained rooftop-powered electrical system for lighting, ventilation, cooling and refrigeration is available to permit extended occupation of the Refuge while rebuilding. The modular design also provides flexibility to later expand the structure with more bedrooms or living spaces.`}</p>
    <p><strong parentName="p">{`Note: In a bushfire or other natural disaster, the Refuge is only intended to provide a last resort to be used when no other safe options are available. Always heed evacuation warnings and other safety advice from the CFS, RFS and other Emergency Services.`}</strong></p>
    <p>{`Explore our options below.`}</p>
    <div className="escape-container">
  <HomeSelector category="categoryRefuge" mdxType="HomeSelector" />
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      